.taxonomy {
  min-height: 700px;
  .taxonomy-management {
    width: 850px;
    &_dd{
       max-width: 300px;
    }
    &_head {
      border: 1px solid var(--bs-blue-gray-100);
    }
    &_icon-sty {
      font-size: 16px;
    }
    &_options {
      // check box
      width: 850px;
      &_label {
        font-size: 14px;
      }
      &_sty {
        height: 30px;
      }
    }
  }
}
.table-field:hover {
  background-color: var(--bs-action-blue) !important;
  color: var(--bs-white) !important;
}
.cm-loader-sty {
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% + 105px);
  top: 3px;
}

// #fileSelect {
//   color: var(--bs-white);
//   max-width: 215px;
// }

// for dropdown
/* Dropdown Button */
// .dropbtn {
//   background-color: #3498db;
//   color: white;
//   padding: 16px;
//   font-size: 16px;
//   border: none;
//   cursor: pointer;
// }

// /* Dropdown button on hover & focus */
// .dropbtn:hover,
// .dropbtn:focus {
//   background-color: #2980b9;
// }

// /* The container <div> - needed to position the dropdown content */
// .dropdown {
//   position: relative;
//   display: inline-block;
// }
