.table-container{
    border: solid;
    box-shadow: none;
    border-width: 1px;
    /* border-top-left-radius: 0; */
    /* border-top-right-radius: 0; */
    border-bottom: none;
    border-color: var(--bs-blue-gray-400)
}

.subskill-input{
    border: solid;
    border-top: none;
    border-width: 1px;
    padding-top: 1rem;
    border-color: var(--bs-blue-gray-400);
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.center-flex{
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center
}

.subskill-input-tag{
    width: 10%;
    margin: 0 0.2rem
}

.subskill-add-button{
    margin: -1.2% 0.4% 0 0;
    display: flex;
    justify-content: center
}

.subskill-add-button-icon{
    border: solid;
    border-width: 2px;
    border-radius: 50%;
    padding: 0.0rem 0.42rem;
    font-size: 1rem;
    font-weight: 900;
}

.cm-event-subskills-rows{
    border-bottom: 1px solid;
    border-color: var(--bs-blue-gray-400);
}
.cm-event-subskills-table{
    border-bottom: 1.5px solid;
    border-color: var(--bs-blue-gray-400) ;
}

.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 3.5px !important;
}