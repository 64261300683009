// change the theme
$theme-colors: (
  "primary": #002C77, //Blue 700
  "gray-50": #f4f4f4, //Blue Gray 50,
  "blue-gray-50": #F8FAFC, //Blue Gray 50,
  "blue-gray-100": #E5EDF4, //Blue Gray 100,
  "blue-gray-300": #BED3E4, //Blue Gray 300,
  "blue-gray-400": #A2B7CD, //Blue Gray 400,
  "blue-gray-500": #8096B2, //Blue Gray 500
  "blue-gray-700": #4E6287, //Blue Gray 700
  "blue-500": #009DE0, // Blue 500,
  "blue-700": #002C77, // Blue 700,
  "blue-800": #001F52, // Blue 800,
  // "gray-100": #F2F4F7, //Gray 100
  "gray-100": #EBEBEB, //Gray 100 2
  "gray-300": #D0D5DD, //Gray 300
  "gray-400": #98A2B3, //Gray 400
  "gray-500": #4E6287, //Gray 500
  "gray-700": #001F52, //Gray 700
  "info-yellow": #FFECB3,
  "action-blue": #0B41AD, //Action Blue
  "action-blue-2": #0E53DD, //Action Blue 2
  "blue": #009DE0, //Blue
  "purple": #8246AF, //Purple
  "success": #14853D,
  "danger": #C53532,
  "action-1": #2C6EF2,
  "orange": #e67300 //Orange
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 0.375,
  10: $spacer * 0.625,
  12: $spacer * 0.75,
  20: $spacer * 1.25,
  32: $spacer * 2,
  40: $spacer * 2.5,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';