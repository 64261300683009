.cm-header-op .cm-txt-resizer {
    margin-right: 0.75rem;
    min-width: 24px;
    min-height: 24px;
}

#txt-resizer .MuiList-root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.75rem;
}

#txt-resizer .MuiList-root > li:first-child {
    width: 100%;
    margin-bottom: 0.5rem;
}

#txt-resizer .MuiList-root > li + li {
    padding: 6px 14px;
    width: 33.33%;
    border: 1px solid var(--bs-blue-gray-300);
}

#txt-resizer .MuiList-root > li:nth-child(2) {
    border-radius: 4px 0px 0px 4px;
    border-right: 0;
}

#txt-resizer .MuiList-root > li:last-child {
    border-radius: 0px 4px 4px 0px;
    border-left: 0;
}

#txt-resizer .MuiPaper-root {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.cm-header-container {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    min-height: 64px;
}

.cm-header-container.cm-user-header {
    position: sticky;
    z-index: 1000;
}

.cm-header-logo-separator {
    width: 1px;
    height: 32px;
}

.cm-user-avatar {
    width: 32px;
    height: 32px;
}

#notif-menu .MuiList-root,
#menu-appbar .MuiList-root {
    padding: 0.5rem;
    border: 1px solid var(--bs-gray-300);
    border-radius: 4px;
}

.cm-notif-item .cm-notif-type {
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
}

.cm-notif-item .cm-content {
    width: calc(100% - 32px);
    white-space: normal;
}

.cm-notif-item + .cm-notif-item {
    border-top: 1px solid var(--bs-blue-gray-300);
}

.cm-menu-has-children {
    position: relative;
}

.cm-menu-has-children > ul {
    position: absolute;
    top: 35px;
    left: 0;
    right: auto;
    min-width: 250px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    list-style: none;
    padding: 0.5rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms ease;
}

.cm-menu-has-children > ul > li > a:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.cm-menu-has-children > ul > li > a.active {
    background-color: rgba(11, 65, 173, 0.05) !important;
}

.cm-menu-has-children > ul:before {
    content: '';
    position: absolute;
    top: -10px;
    height: 10px;
    width: 100%;
    left: 0;
}

.cm-menu-has-children:hover > ul {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

/* Responsive */

@media screen and (max-width: 767px) {
    
    .cm-header-container {
        padding: 0.75rem 0 !important;
        position: relative;
    }

    .cm-header-container .navbar-nav,
    .cm-header-container .cm-header-logo-separator {
        display: none !important;
    }

}