.cm-graph-fs,
.vis-navigation .vis-button.vis-zoomIn,
.vis-navigation .vis-button.vis-zoomOut {
    position: absolute;
    right: 40px;
    top: 24px;
    border: 1px solid var(--bs-blue-gray-100);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 9;
    cursor: pointer;
}

.vis-navigation .vis-button.vis-zoomIn {
    right: 120px;
}

.vis-navigation .vis-button.vis-zoomOut {
    right: 80px;
}

.vis-navigation .vis-button.vis-zoomIn:after {
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    background-position: center;
    background-image: url('../../assets/images/network/plus.png');
}

.vis-navigation .vis-button.vis-zoomOut:after {
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    background-position: center;
    background-image: url('../../assets/images/network/minus.png');
}