.w-30{
    width: 30%;
}

.w-70{
    width: 70%;
}

.mt-bar{
    margin-top: 4rem;
}

.skill-box{
    padding: 1rem 2rem;
    background-color: var(--bs-blue-gray-100);
    border: solid;
    border-width: 1px 0 1px 0;
    border-color: var(--bs-blue-gray-400);
    color: var(--bs-blue-gray-700);
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
}

.skill-box:hover {
    background-color: var(--bs-blue-gray-300);
    cursor: pointer;
}

.question-card{
    border: solid;
    border-width: 1px;
    margin: 2rem 2rem;
    border-color: var(--bs-blue);
    border-radius: 4px;
    padding: 1rem 1rem;
}

.ques-id{
    font-size: 14px;
    color: var(--bs-primary);
    font-weight: 700;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border : 1px solid red; */
}

.ques-text{
    font-size: 15px;
    color: var(--bs-blue-800);
    margin-top: 1rem;
}

.selectBox-txt{
    font-size: 14px;
    color: var(--bs-blue-800);
}

.selectBox{
    display: flex;
    align-items: center;
    gap: 1rem;
    /* border: 1px solid green; */
}

.mcq-tags{
    padding: 4px 6px;
    margin-right: 0.5rem;
    border-radius: 4px;
    background-color: var(--bs-blue-gray-500);
    color: white;
    font-weight: 200;
    font-size: 14px;
}

.answer-text{
    font-size: 14px;
    color: var(--bs-blue-800);
    font-weight: 400;
}

.left-container{
    overflow-y: scroll;
}

.right-container{
    border-left: solid;
    border-width: 1px;
    border-color: var(--bs-gray-300);
    background-color: var(--bs-gray-50);
    overflow-y: scroll;
}

.count-circle{
    background-color: var(--bs-action-blue);
    color: white;
    width: 1.5rem;
    border-radius: 50%;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-hide {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scroll-hide::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.filter-bar{
    position: fixed;
    background-color: var(--bs-gray-50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.15rem 2rem;
    width: 70%;
}

.filter-options{
    display: flex;
}

.filter-style{
    color: var(--bs-primary);
    font-weight: 600;
}

.check-box{
    margin-right: 0.5rem;
    margin-bottom: -0.1rem;
}

.review-btn{
    font-size: 12px;
    padding: 0px 6px;
    height: 2rem;
    border-radius: 4px;
    background-color: var(--bs-action-blue);
    color: white;
    font-weight: 600;
    border: 1px solid  var(--bs-action-blue);
    outline: none;
}

.review-btn:hover{
    background-color: var(--bs-action-blue-2);
    border: 1px solid  var(--bs-action-blue-2);
}

.subskill-txt{
    font-weight: 700;
    font-size: 1.1rem;
    color: #002C77;
}

.mcqCardConatinerBottom{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.reviewerChoiceSendBtn{
    border: none;
    outline: none;
    padding: 2px 10px;
    background-color: #002C77;
    color: white;
    font-size: small;
    border-radius: 4px;
    cursor: pointer;
}

/* .btnDisabled{
    cursor: none;
    opacity: 0.6;
} */
/* pre{
    background: #c7c7c74d;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    color: #353535;
    direction: ltr;
    font-style: normal;
    min-width: 200px;
    padding: 1em;
    tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    font-family: var(--bs-font-monospace);
    margin: 8px !important;
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
} */
.bank-ques-id{
    font-size: 14px;
    color: var(--bs-primary);
    margin-bottom: 0.8rem;
    justify-content: space-between;
    align-items: center;
}

.cm-flag-icon{
    margin-right: 15px;
}
.cm-event-flag-container{
    display: inline-block;
    width: 100%;
}
.select-flag-bar{
width: 100%;
border-color: var(--bs-gray-300) !important; 
}
.css-t3ipsp-control {
    border-color:var(--bs-gray-300) !important;
}

.cm-flag-comment{
resize: none;
}



.cm-comment-modal-active.bank-question-card .cm-right-col {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    right: 0;
    top: 0;
}

.bank-question-card .cm-right-col .cm-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.bank-question-card .cm-right-col .cm-comment-body {
    width: 350px;
}

.bank-question-card .cm-right-col .cm-comment-row-1 {
    height: calc(100% - (56px + 41px));
}

.mcqCardConatinerBottomTagsPreviewCard {
    width: calc(100% - 64px)
}

#review-question{
    transform: scale(1.3);
}

.cm-resolve-comment{
    width: calc(100% - (56px + 350px));
}
.text-red{
    color: rgb(238, 75, 43);
}
 
.text-green{
    color: green;
    text-align: right;
}

#comment-filters{
    font-size: 14px;
    width: 100px;
    padding: 8px 10px;
    border-radius: 0px !important;  
    height: 10px;
}

