.cm-create-bank-form-container.cm-add-bank-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}
.cm-create-modal-form-header{
    border-bottom: 1px solid var(--bs-blue-gray-300);
}
.cm-create-modal-form-footer{
    border-top: 1px solid var(--bs-blue-gray-300);
    padding: 1rem 1rem;
}
.cm-Pharse-search-container{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 700px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}

.selectOpionsCont {
    max-height: 220px;
    overflow-y: auto;
}



