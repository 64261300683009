.cm-add-bank-btn {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
}
.css-1vtffwz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: rgba(var(--bs-primary-rgb)) !important;
    color: #ffff !important;
}

.css-1vtffwz-MuiButtonBase-root-MuiPaginationItem-root {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-blue-800-rgb), var(--bs-text-opacity)) !important;
}

.cm-event-pagination {
    background-color: #ffff;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 50px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid;
    border-color: var(--bs-blue-gray-100);
    ;
}

.cm-event-pagenumbers {
    margin-left: auto;
    margin-right: 15px;
}

.cm-event-bank-questions {
    padding-bottom: 48px;
}

.cm-my-bank-body {
    background-color: #F8FAFC !important;
    height: 100%
}

.css-n87fdm-MuiSimpleTreeView-root {
    height: 90vh !important

}

.css-63iox8-MuiTreeItem-content .MuiTreeItem-label {
    overflow: hidden;
    font-size: 0.75rem !important;
}

.cm-event-Bank-sidebar {
    width: 400px;
    padding: 1rem 0;
    background-color: var(--bs-white);
    position: sticky;
    top: 114px;
    overflow-y: auto;
    left: 0;
    height: calc(100vh - 150px);
}

.cm-bank-content-wrapper {
    width: calc(100% - 430px);
}

.css-63iox8-MuiTreeItem-content .MuiTreeItem-label{
    display: flex;
}
.cm-question-progress-bar {
position: absolute;
top: 50%;
left: 50%;
}
.cm-event-bank-label {
    flex:1;
    padding: 3px;
    word-break: auto;
}

.cm-event-bank-label > span {
    font-size: 12px;
    border-radius: 8px;
    flex: none;
}

.cm-empty-container{
    position: absolute;
    top:50%;
    left:50%;
}

.cm-question-count{
    min-width: 35px;
    border-radius:8px;
}

.cm-add-filter{
    width: 400px;
}

.cm-filter-actions{
    cursor: default !important;
}

.cm-filter-actions .cm-apply-btn{
    padding: 6px 12px;
}

.cm-candidate-pill{
    background: rgba(140, 159, 193, 0.1);
    color: #526381;
    border-radius: 8px;
    padding: 6px 10px;
}

#contains-select{
    width: 120px;
    padding: 8px 10px;
    border-radius: 0px !important;  
}

.cm-contains-field{
    flex-grow : 0;
    font-size: 12px;
   
}

.cm-contains-field >.css-r1l6iq-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    border-radius: 0px !important; 
}
.searchtext-field{
    flex-grow : 1;
    margin-right: 0px !important    ;
}

.user-input-search-bar{  
    padding-left: 30px;
    padding-top: 9px;
    padding-bottom: 9px;
    width: calc(100%);
    line-height: 20px;
    font-size: 12px;
    border: 1px solid #c3d7ea;
    
}
 
.user-input-search-bar:focus{
    border: 1px solid #c3d7ea;
    outline: none !important;
    box-shadow: 0 0 1px #719ece;
}

.user-input-icon{
    color: #6783b1;
    top: calc(30%);
   left: 10px;
}

.user-info-icon{
    color: #6783b1;
    top: calc(50%);
    right: 5px;
    font-size: 20px;
}

.search-bar{
    border: 1px solid #bed3e4;
    width: calc(100% - 140px);
}

.cm-info-icon{
    border: 1px solid #c3d7ea;
}

@media screen and (max-width: 1500px) {
    
    .cm-event-Bank-sidebar {
        width: 320px;
    }
    
    .cm-bank-content-wrapper {
        width: calc(100% - 350px);
    }

}

.delete-btn {
    padding: 0.5rem 0.65rem;
    height: 100%;
    border-radius: 4px;
}

.trash-icon {
    background-color: rgb(255, 24, 65);
    color: #fff;
    font-size: 15px;;
}
