.otpContainer > input {
    width: 52px;
    height: 52px;
    text-align: center;
    border: 1px solid var(--bs-blue-gray-300);
    color: var(--bs-blue-800);
    font-weight: 500;
    border-radius: 4px;
}

.otpContainer > input::-moz-selection {
    background: transparent;
  }
  
.otpContainer > input::selection {
    background: transparent;
  }