.cm-bank-box-container {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 1px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    width: calc(25% - 32px);
    margin: 0 16px 16px;
}

.cm-bankcard-container {
    margin: 32px 24px;
    padding-bottom: 64px;
}

.cm-event-page {
    background-color: #ffff;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 8px;
    border-top: 1px solid;
    height: 47px;
    border-color: var(--bs-blue-gray-100);
    ;
}

.translate-label {
    margin-left: 15px;
}

.cm-bank-body{
    justify-content: space-between;
}

.cm-bank-avatar {
    width: 24px;
    height: 24px;
}