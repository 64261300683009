.cm-modal-task-item {
    border: 1px solid var(--bs-blue-gray-100);
    border-radius: 8px;
}

.cm-modal-task-item+.cm-modal-task-item {
    margin-top: 0.5rem;
}

.cm-modal-task-item .cm-modal-task-info {
    list-style: none;
    margin-top: 0.625rem;
}

.cm-modal-task-item .cm-modal-task-info li+li {
    margin-left: 1.125rem;
}

.cm-modal-task-item .cm-add-modal-task-btn {
    padding: 0.25rem 0.5rem;
    width: 28px;
    height: 28px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.cm-add-modal-task-btn.cm-modal-task-exists {
    border-color: var(--bs-danger);
}

.cm-task-already-added-btn {
    background: rgba(0, 157, 224, 0.1);
    border-radius: 16px;
    padding: 1.5px 10px;
}

.cm-modal-task-item .cm-name-avtar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--bs-gray-100);
}

.cm-modal-task-item .cm-content {
    width: 100%;
}

.cm-modal-task-item .cm-candidate-pill {
    padding: 6px 24px;
    background: rgba(14, 43, 114, 0.1);
    border-radius: 16px;
    color: #0E2B72;
}

.cm-modal-task-item .cm-candidate-pill.cm-completed {
    background: rgba(20, 133, 61, 0.1);
    color: #14853D;
}

.cm-modal-task-item .cm-candidate-pill.cm-expired {
    background: rgba(219, 77, 138, 0.1);
    color: #DB4D8A;
}

.cm-modal-task-item .cm-candidate-pill.cm-yet-to-start {
    background: rgba(140, 159, 193, 0.1);
    color: #526381;
}

.reviewer-delete-button {
    position: relative;
    top: -49px;
    right: -94px;
}