.cm-profile-sidebar {
    padding-left: 2rem;
    padding-right: 2rem;
}

.cm-profile-sidebar li > span {
    padding-left: 0.75rem;
}

.cm-profile-sidebar-sh {
    padding: 0.75rem 1rem;
}

.cm-profile-content-wrap {
    padding: 0 3rem 2rem;
}

.cm-profile-banner img {
    width: 100%;
}

.cm-banner-user-icon {
    background: #E5EDF4;
    border: 2px solid #FFFFFF;
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%;
    left: 56px;
    bottom: -40px;
    position: absolute;
}

.cm-banner-user-icon svg {
    font-size: 64px;
}

.cm-profile-content-wrap-header {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    border-radius: 0 0 8px 8px;
}

.cm-profile-content-wrap-header .cm-profile-info {
    padding: 3.5rem 3rem 2rem;
}

.cm-profile-info > h4 {
    font-size: 20px;
}

.cm-personal-details-wrap {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: var(--bs-white);
    padding: 2.5rem;
    margin-top: 1rem;
}

.cm-icon-field .cm-pass-visiblility-changer,
.cm-icon-field .cm-mob-icon {
    right: 15px;
    cursor: pointer;
    top: 8px;
    font-size: 1rem;
}

.cm-profile-form input {
    padding: 0.75rem 1rem 0.75rem 42px;
    font-size: 0.875rem;
}

.txt-size-lg .cm-profile-form input {
    font-size: calc(0.875rem + 2px);
}

.txt-size-sm .cm-profile-form input {
    font-size: calc(0.875rem - 2px);
}

.cm-profile-form a {
    text-decoration: none;
}

.cm-profile-form .cm-prim-btn:hover,
.cm-profile-form .cm-prim-btn:focus,
.cm-profile-form .cm-prim-btn:active {
    background-color: var(--bs-blue-500) !important;
    border-color: var(--bs-blue-500) !important;
}

.cm-profile-form .cm-trans-btn:hover,
.cm-profile-form .cm-trans-btn:focus,
.cm-profile-form .cm-trans-btn:active {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-blue-gray-300);
}

.cm-start-icon > svg {
    top: calc(50% - 8px);
    left: 16px;
}

.cm-mult-icon-wrap {
    position: absolute;
    right: 12px;
    top: 15px;
}

.cm-mult-icon-wrap svg {
    margin-top: 0;
}

.cm-mult-icon-wrap svg + svg {
    margin-left: 12px;
}

/* Responsive */

@media screen and (max-width: 767px) {
    
    .cm-profile-sidebar {
        display: none;
    }

    .cm-profile-content-wrap {
        padding: 0 0 2rem;
    }

    .cm-personal-details-wrap {
        padding: 1.5rem;
    }

    .cm-profile-form input {
        padding: 0.625rem 1rem 0.625rem 42px
    }

    .cm-banner-user-icon {
        width: 48px;
        height: 48px;
        bottom: -24px;
        left: 24px;
    }
    
    .cm-banner-user-icon svg {
        width: 40px;
        height: 40px;
    }
    
    .cm-profile-content-wrap-header .cm-profile-info {
        padding: 2.25rem 1.5rem 1.5rem;
    }

}

@media screen and (max-width: 420px) {
    
    .cm-form-btn-group > .cm-btn {
        min-width: auto;
    } 

}