body {
    background-color: var(--bs-blue-gray-50);
}

.cm-home-dash-content-wrapper .cm-home-dash-top-cards {
    padding: 0 2.5rem;
}

.cm-home-dash-widget-header > div:first-child {
    width: calc(100% - 32px);
}

.cm-home-dash-widget-header > div:first-child > p {
    white-space: nowrap;
}

.cm-home-dash-widget .cm-home-dash-widget-header {
    border: 1px solid var(--bs-blue-gray-100);
    border-radius: 8px 8px 0px 0px;
}

.cm-home-dash-widget-footer,
.cm-home-dash-widget-content {
    border: 1px solid var(--bs-blue-gray-100);
    border-top: none;
}

.cm-admin-home-dash-top-col .cm-admin-pie-chart .cm-home-dash-widget-content {
    min-height: 285px;
}

.cm-home-dash-top-cards .cm-home-dash-item {
    padding: 1rem 2rem;
    border-radius: 4px;
}

.cm-home-dash-top-cards .cm-home-dash-item + .cm-home-dash-item {
    margin-left: 1rem;
}

.cm-sme-dash-top-cards .cm-home-dash-item {
    width: calc(25% - 2rem);
    margin: 1rem 1rem 0;
}

.cm-home-dash-top-cards .cm-home-dash-item h3 {
    font-size: 32px;
    background: linear-gradient(263.66deg, #00AC41 0%, #009DE0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.cm-add-new-event-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.cm-event-library-tabber .MuiTabs-flexContainer {
    background-color: var(--bs-gray-50);
    border-radius: 8px;
    padding: 0.25rem;
}

.cm-event-library-tabber .MuiTabs-flexContainer button {
    padding: 8px 43px;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 1rem;
    text-transform: none;
}

.cm-event-library-tabber .MuiTabs-flexContainer button.Mui-selected {
    background-color: var(--bs-white);
    border-radius: 4px;
}

.cm-event-library-tabber .MuiTabs-indicator {
    display: none;
}

#more-menu .MuiPaper-root {
    border: 1px solid var(--bs-blue-gray-300);
}

.cm-event-library-sidebar-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cm-event-library-sidebar-nav li {
    padding: 0 2rem;
}

.cm-event-library-sidebar-nav li a {
    padding: 1.25rem;
    display: block;
}

.cm-event-library-sidebar-nav li a span {
    padding-left: 0.75rem;
}

.cm-candidate-search-field input[type="text"] {
    padding: 0.625rem 1rem;
    border: 1px solid var(--bs-gray-300);
    width: 300px;
    background-color: var(--bs-blue-gray-50);
    border-radius: 4px;
    padding-right: 2.25rem;
}

.cm-candidate-search-field svg {
    top: calc(50% - 8px);
    right: 12px;
}

.cm-candidate-report-list-container {
    padding: 0 0 2rem;
    background-color: var(--bs-white);
}

.cm-candidate-report-list-container .cm-candidate-report-list-header {
    border-top: 1px solid var(--bs-blue-gray-100);
    border-bottom: 1px solid var(--bs-blue-gray-100);
}

.cm-candidate-report-list-container .cm-candidate-report-list-header > div {
    padding: 0.75rem 1rem;
}

.cm-candidate-report-list-container .cm-candidate-report-item > div {
    padding: 1rem 1rem;
}

.cm-candidate-report-list-container .cm-candidate-report-item {
    border-bottom: 1px solid var(--bs-blue-gray-100);
    background-color: var(--bs-white);
}

.cm-candidate-report-item .cm-candidate-pill {
    padding: 6px 24px;
    background: rgba(14, 43, 114, 0.1);
    border-radius: 16px;
    color: #0E2B72;
}

.cm-candidate-report-item .cm-candidate-pill.cm-completed {
    background: rgba(20, 133, 61, 0.1);
    color: #14853D;
}

.cm-candidate-report-item .cm-candidate-pill.cm-expired {
    background: rgba(219, 77, 138, 0.1);
    color: #DB4D8A;
}

.cm-candidate-report-item .cm-candidate-pill.cm-yet-to-start {
    background: rgba(140, 159, 193, 0.1);
    color: #526381;
}

.cm-invited-cand-list-wrap .cm-invited-cand-list {
    padding: 6px 24px;
    border: 1px solid var(--bs-blue-gray-100);
    border-radius: 8px;
}

.cm-view-cand-trigger {
    padding: 0.5rem 0.75rem;
}

.cm-add-cand-wrapper {
    margin-top: 32px;
}

.cm-event-controls,
.cm-add-cand-container {
    margin-top: 2rem;
}

.cm-add-cand-container .cm-add-cand-wrapper {
    border-radius: 8px;
    padding: 1.5rem;
}

.cm-add-cand-item .cm-add-cand-col {
    width: calc(50% - 28px);
}

.cm-delete-cand-col {
    width: 36px;
    height: 36px;
    border: 1px solid var(--bs-danger);
    border-radius: 4px;
    margin-top: 34px;
}

.cm-add-cand-controls .cm-add-cand-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-action-blue);
    display: inline-block;
    border-radius: 4px;
}

.cm-add-cand-controls .cm-bulk-upload-cand-btn {
    border: 1px solid var(--bs-blue-gray-300);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
}

.cm-event-controls .cm-share-ev-btn {
    padding: 0.625rem 1rem;
    border-radius: 4px;
}

.cm-event-controls .cm-clear-ev-btn {
    border: 1px solid var(--bs-blue-gray-300);
    padding: 0.625rem 1rem;
    margin-left: 0.5rem;
    border-radius: 4px;
}

.cm-bulk-upload-cand-btn .cm-bulk-file-inp {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.cm-event-pagination-container {
    padding-bottom: 24px;
}

.cm-event-pagination-container .cm-event-page-btn {
    padding: 0.625rem 1rem;
    border-radius: 4px;
}

.cm-add-cand-item input {
    padding: 0.625rem 1rem;
}

.cm-pagin-disable {
    opacity: 0.5;
    cursor: default;
}

.cm-admin-home-dash-top-col .cm-home-dash-top-cards .cm-home-dash-item {
    width: calc(50% - 16px);
    margin-left: 0;
    margin-bottom: 1rem
}

.cm-admin-home-dash-top-col .cm-admin-pie-chart {
    padding-left: 2.5rem;
}

.cm-admin-home-dash-top-col .highcharts-credits {
    display: none;
}

#date-range-menu .MuiList-root {
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

#date-range-menu .MuiPaper-root {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

#date-range-menu .MuiButtonBase-root {
    padding: 0;
}
/* Responsive */
@media screen and (max-width: 1550px) {
    
 #actionAdminDashboard
{
    padding-left: 0;
    justify-content: flex-start !important;
}

#actionAdminDashboard .cm-add-quest-btn
{
    margin-left:0.5rem !important;
}

}

