.cm-trans-mode-rows .cm-left-col {
    width: 40%;
    top: 120px;
    left: 0;
    height: calc(100vh - 120px - 64px);
}

.cm-trans-mode-rows .cm-right-col {
    width: 60%;
    top: 120px;
    right: 0;
    height: calc(100vh - 120px - 64px);
}

.cm-trans-mode-rows .cm-answer-choice-editor .ck.ck-editor__editable {
    min-height: 37px !important;
}

.cm-trans-mode-rows .cm-answer-choice-editor .ck.ck-editor__editable .ck-placeholder {
    margin: 0;
}

.cm-trans-mode-rows .cm-trans-mode-edit-row {
    height: calc(100% - 56px - 42px);
}

.cm-trans-mode-footer {
    bottom: 0;
    width: 100%;
}

.cm-section-heading {
    display: inline-block;
    color: var(--bs-white);
    background-color: var(--bs-blue-700);
    /* border-left: 6px solid var(--bs-pink); */
    /* padding: 0.625rem 1rem; */
    font-weight: 500;
    border-radius: 4px;
}