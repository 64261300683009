.cm-quest-lib-container .cm-quest-lib-top-cards {
    padding: 0 2.5rem;
}
.cm-quest-lib-top-cards .cm-quest-lib-top-item {
    padding: 1.5rem 2rem;
    border-radius: 4px;
    min-width: 250px;
}

.cm-quest-lib-top-cards .cm-quest-lib-top-item + .cm-quest-lib-top-item {
    margin-left: 1rem;
}

.cm-quest-lib-top-cards .cm-quest-lib-top-item .cm-gradient-txt {
    font-size: 24px;
    background: linear-gradient(263.66deg, #00AC41 0%, #009DE0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.cm-truncate-1 {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cm-add-quest-btn {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
}

.cm-report-download-btn {
    font-size: 18px;
}

.cm-quest-lib-actions {
    list-style: none;
}

.cm-quest-lib-actions li + li:before {
    content: '|';
    color: var(--bs-blue-gray-500);
}