

.cm-taskdetail-modal-footer {
    border-top: 1px solid var(--bs-blue-gray-100);
    padding: 1rem 2rem;
}

.cm-taskdetail-modal-header {
    border-bottom: 1px solid var(--bs-blue-gray-100);
}

.cm-taskdetail-modal-footer button {
    font-size: 14px;
}

.cm-taskdetail-modal-footer button+button {
    margin-left: 0.75rem;
}

.cm-taskdetail-modal-footer .cm-trans-btn:hover {
    color: var(--bs-white) !important;
}

.cm-tasklist-modal-wrapper .MuiBox-root {
    height: 100% !IMPORTANT;
}

#vendor-select {
    /* height: 1rem !important; */
    /* text-align: center; */
    /* vertical-align: middle; */
    /* padding-top: 12px !important;
    padding-bottom: 12px; */
    padding: 0.5rem;
}
.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0.2rem 1rem;
}


.cm-completed {
    background: rgba(20, 133, 61, 0.1);
    color: #14853D;
}

.cm-create-task-form-container.cm-lang-modal-container {
    max-width: 1200px;
}

.cm-plagiarism-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 800px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}

.cm-result-table .cm-result-table-content {
    height: 342px;
    overflow: auto;
    border: 1px solid var(--bs-blue-gray-100);
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.cm-result-table .cm-result-table-header {
    border: 1px solid var(--bs-blue-gray-100);
    border-radius: 4px 4px 0 0;
}

.cm-result-table .cm-result-table-header .cm-err-count {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.cm-lang-modal-save-btn.cm-disable-btn {
    opacity: 0.5;
    pointer-events: none;
}

.cm-lang-modal-save-btn:hover {
    color: var(--bs-white) !important;
}

.cm-err-editor-modal {
    width: 350px;
}

.cm-err-editor-modal .cm-err-editor-footer span {
    border-radius: 4px;
    margin: 0 4px 4px;
}

.cm-question-creation-guidline-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 800px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}

.cm-question-creation-guidline-data {
    height: 70vh;
    overflow: auto;
}

.cm-question-creation-guidline-footer {
    border-top: 1px solid var(--bs-blue-gray-300);
    padding: 1rem 2rem;
}

.cm-question-creation-guidline-header {
    border-bottom: 1px solid var(--bs-blue-gray-300);
}

.ques-num{
    background-color: #4E6287;
    color: white;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 14px;
}

.flagContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    /* border: 1px solid red; */
}

.flagIcon{
    color:#6bafbd;
}

.flagIcon:hover{
    color:grey;
}

.optionsDropDown{
    width: 100%;
}
.optionsDropDown .css-11bwk2j-MenuList{
max-height: 190px;
}