.user-input {
  &_search-bar {
    padding-left: 30px;
    padding-top: 9px;
    padding-bottom: 9px;
    min-width: 300px;
    line-height: 20px;
    font-size: 12px;
    border: 1px solid #bed3e4;
  }
  &_search-bar:focus {
    border: 1px solid #c3d7ea;
    outline: none !important;
    box-shadow: 0 0 1px #719ece;
  }
  &_icon {
    color: #6783b1;
    position: relative;
    top: calc(50% - 25px);
    right: 290px;
    font-size: 12px;
  }
}
.taxonomy-management{
  min-height: 600px;
}
.cm-skill-info {
  width: 20px;
  &_p {
    color: #2aa775;
  }
  &_s {
    color: #ffd265;
  }
  &_b {
    color: #e8544e;
  }
  &_o {
    color: #719ece;
  }
  &_e {
    color: #d44ee8;
  }
  &_w {
    color: #0000FF;
  }
}

.toggle-sty {
  position: sticky;
  top: 239px;
  z-index: 1;
  width: 38px;
  &_icon {
    position: absolute;
    font-size: 14px;
    border: 1px solid var(--bs-blue-gray-100);
    border-left: 0;
    border-radius: 0px 4px 0px 0px;
    width: 39px;
    height: 37px;
  }
  &_icon:last-child {
    top: 36px;
    border-radius: 0px 0px 4px 0px;
  }
}
.new-nodeGraph-sty {
  border: 1px solid var(--bs-blue-gray-100);
}

// style for popup on hover
.vis-tooltip {
  font-family: "Noto Sans", sans-serif;
  position: absolute;
  color: rgba(var(--bs-blue-800-rgb), var(--bs-text-opacity));
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--bs-blue-gray-100);
  font-size: 10px;
}
.nothingToSee {
  height: 500px;
}

// full screen
.container-graph{
  min-height: 500px;
  border: 1px solid var(--bs-blue-gray-100);
}

#network {
  width: 100%;
  height: 100%;
  border: 1px solid green;
  position: static;
  top: 0;
  left: 0;
}

#container-graph:fullscreen {
  width: 100vw;
  height: 100vh;
}

#fullscreenonly {
  position: absolute;
  top: 0;
  z-index: 20;
  left: 0;
}
#only-fullscreen {
  display: "none";
}

.cm-search-dropdown {
    position: absolute;
    top: 100%;
    background-color: #fff;
    width: 200px;
    border: 1px solid var(--bs-blue-gray-100);
    margin-top: 4px;
    margin-bottom: 0;
    border-radius: 4px;
    list-style: none;
    padding: 0;
}

.cm-search-dropdown li + li {
    border-top: 1px solid var(--bs-blue-gray-100);
}

.cm-action-menu .MuiPaper-root {
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.cm-action-menu .MuiButtonBase-root {
  font-size: 14px;
}

.cm-action-menu .MuiButtonBase-root {
  padding: 6px 16px;
}

.cm-action-menu .MuiButtonBase-root.Mui-focusVisible {
  background-color: rgba(25, 118, 210, 0.08);
}

.cm-action-menu .MuiMenu-list {
  padding: 0.5rem 0;
}