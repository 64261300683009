

.welcome {
    font-weight: 400;
    font-size: 32px;
    color: #4E6287;
    font-family: 'MMC Display'!important;
    font-style: normal;
    line-height: 48px;
  }
  
  .title {
    font-family: 'MMC Display'!important;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: #002C77;
  }

  .loginLabel {
    font-family: 'Noto Sans', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #001F52;
    position:fixed;
    left:5.5%;
    margin-top:0.8vw !important;
  }
  
  .divider {
    color: #4E6287;
    margin-bottom:2rem!important;
    margin-top:5rem !important;
  }
  
  .labelForm {
    font-family: 'Noto Sans', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #4E6287;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .form1 {
  
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;
    width: 40vw;
    height: 3vw;
  
    /* Background/White */
  
    background: #FFFFFF;
    /* Borders/Blue Gray 300 */
  
    border: 1px solid #BED3E4;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  
  #form2{
  
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;
  
    width: 589px;
    height: 40px;
  
    /* Background/White */
  
    background: #FFFFFF;
    /* Borders/Blue Gray 300 */
  
    border: 1px solid #BED3E4;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  
  .login {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 94px;
    height: 40px;
    background: #0B41AD !important;
    border-radius: 4px;
    font-size:14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white !important;
  }
  
  
  .forgot {
  
    font-family: 'Noto Sans', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #009DE0 !important;
    flex: none;
    order: 2;
    flex-grow: 0;
  }
  
  
  div.centered {
    position: fixed;
    /* or absolute */
    top: 25%;
    left: 22.5%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  
  .rownew {
    width: 100%;
  }
  
  .new-centered {
    position: fixed;
    /* or absolute */
    top: 55%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  
  #form {
    position: fixed;
    left:2.5%;
  }
  
  .createAccount
  {
    box-sizing: border-box;
  
  /* Auto layout */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  
  width: 177px;
  height: 40px;
  
  /* Background/White */
  
  background: #FFFFFF;
  /* Borders/Blue Gray 300 */
  
  border: 1px solid #BED3E4 !important;
  border-radius: 4px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left:15px !important;
  }

  .active>a,
  .nav li>a:hover {
    color: #FFFFFF;
    text-decoration: none !important;
    background-color: #001F52 !important;
    font-weight: 500;
  
  }

  .fa-chevron-right
  {
    -webkit-text-stroke: 1px #0B41AD;
  }

  .text-area
{
  overflow:hidden;
  resize:none;
  height:10vh;
  width:600px !important;
}

.error
{
  color: #ce2f2f;
    font-weight: 500;
}

  