.cm-view-task-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  overflow: auto;
  max-height: calc(100vh - 2rem);
}

.cm-task-item {
  width: 100%;
}

.cm-task-list-item {
  height: 50vh;
  overflow-y: auto;
}