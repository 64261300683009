
.headText
{
    font-family: 'Noto Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* Text/Blue 800 */

color: #001F52;
}

.labelText{
    font-family: 'Noto Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 12px;
color:#4E6287;
}

.cm-box{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 16px;
gap: 10px;

width: 300px !important;
height: 40px;

/* Background/White */

background: #FFFFFF;
/* Borders/Blue Gray 300 */

border: 1px solid #BED3E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
}

.cm-box-lg
{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 16px;
gap: 10px;


/* Background/White */

background: #FFFFFF;
/* Borders/Blue Gray 300 */

border: 1px solid #BED3E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.border-sm
{
    border: 1px solid #BED3E4;
}

.breadcrumb-position
{
    position:sticky;
    top:64px;
    border-bottom: 1px solid #BED3E4;
}

.body
{
    background-color: #F5F5F5;
    height:100vh;
}