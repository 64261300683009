.cm-download-quest-bank-container .MuiAutocomplete-input {
    padding: 0 !important;
}

.cm-download-quest-bank-container .MuiAutocomplete-option {
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--bs-blue-800);
}

.cm-download-quest-bank-container .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
}