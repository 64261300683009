.cm-red-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.cm-lang-result-item ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cm-lang-result-item ul li {
    border-radius: 4px;
    margin: 0 4px 4px;
}