.cm-upload-bank-questions-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    max-width: 500px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    z-index: 9999 !important;

}

.cm-upload-bank-questions-header {
    border-bottom: 1px solid var(--bs-blue-gray-300);
}

.cm-upload-bank-questions-body {
    display: block;
}

/* .cm-event-upload{
float: r;
} */

.cm-event-fileupload {
    border-radius: 4px;
    height: 350px;
    margin: 1rem;
    border-color: 1 px solid var(--bs-border-color);
    overflow: hidden;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

.labelwrap label{
width: 100%;
word-wrap: break-word;
}

.cm-event-upload-option{
    margin: 1rem 2rem;
    width: 90%;
}

.file-upload-icon {
    margin-bottom: 15px;
}

.cm-event-drag-drop{
    border: solid;
    margin: 1rem 1rem;
    border-color:  var(--bs-gray-300);
    border-style: dashed;
    border-radius: 4px;
    padding: 70px 0px;
    width: 90%;
    height: 30vh;
    flex-direction: column;
}

.cm-event-get-template {
    border-radius: 4px;
    width: 400px;
    height: 40px;
    margin-left: 25%;
    border-color: 1 px solid var(--bs-border-color);
    /* border-width: var(--bs-border-width); */
    /* border-style:dashed; */
    /* border:; */
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.line{
    display:block;
    width:100%;
    border-bottom:1px solid var(--bs-border-color); 
    text-align:center
}
.line h2{
    font-size:15px; 
    text-align:center; 
    position:relative; 
    top:15px; 
    padding:0 15px; 
    display:inline-block; 
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-blue-gray-50-rgb), var(--bs-bg-opacity)) !important;
}

.cm-upload-status{
    margin-top: 50px;
}

.cm-upload-bank-question-language-support {
    /* width: auto;
    height: 30px; */
    /* display: flex; */
    border: 1px solid red;
}
