.cm-dialag-container .MuiDialogTitle-root {
    padding: 1.5rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--bs-blue-800);
    position: relative;
}

.cm-dialag-container .MuiDialogTitle-root button {
    top: calc(50% - 20px);
    color: var(--bs-blue-800);
}

.cm-dialag-container .MuiDialogContent-root {
    padding: 1.5rem 2rem !important;
}

.cm-dialag-container .cm-dialog-cancel-btn {
    border: 1px solid var(--bs-action-blue);
}

.cm-dialag-container .cm-dialog-cancel-btn,
.cm-dialag-container .cm-dialog-action-btn {
    padding: 0.625rem 1rem;
    font-size: 14px;
    text-transform: none;
}

.cm-dialag-container .MuiPaper-root {
    min-width: 600px;
}

.cm-download-report-form .cm-date-range-val {
    padding: 0.625rem 1rem;
    border: 1px solid #ced4da;
}

.cm-download-report-form #vendor-select {
    padding: 0.625rem 2rem 0.625rem 1rem;
    font-size: 0.875rem !important;
}

.cm-download-report-form fieldset {
    border: 1px solid #ced4da;
}