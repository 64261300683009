.navbarBrand {
    color: #001F52 !important;
}

#addUser {
    border: 1px solid #BED3E4 !important;
    background-color: #1976d2;
    color: white;
    left: 45%;
    position: relative
}

#modalStyle  {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
};

.form1 {
  
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;
    width: 40vw;
    height: 3vw;
  
    /* Background/White */
  
    background: #FFFFFF;
    /* Borders/Blue Gray 300 */
  
    border: 1px solid #BED3E4;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }