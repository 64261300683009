.box
{
    box-sizing: border-box;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #BED3E4 !important;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.wd{
    width:53% !important;
    display:flex;
}

.col
{
flex: 0 0 auto;
width: 52.666667%;
}

.btnMargin
{
    margin-left:35vw;
    margin-top:1vw;
    border: 1px solid #BED3E4 !important;
    background-color: #1976d2;
    color:white;
}

.borderBreadcrumb
{
    border-bottom:1px solid #BED3E4 !important;
    background-color:white;
}

.background
{
    background-color:#F5F5F5;
    max-height:200vh !important;
    height:140vh;
}

.breadcrumbDivider
{
    background-color:white;
    border-bottom: 1.5px solid #E5EDF4;
}



