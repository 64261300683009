.inputStyle {
  width: 45px !important;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  margin-left: 8px;
  margin-right: 8px;
  background: #fff;
  font-size: 20px;
  color: var(--bs-blue-gray-700-rgb) !important;
  padding-left: 15px;

}