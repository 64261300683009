.cm-breadcrumb-container {
    border-bottom: 1px solid var(--bs-blue-gray-100);
    position: sticky;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 9;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.cm-breadcrumb-container > div {
    min-height: 50px;
}

.cm-breadcrumb-container .cm-btn-trans {
    font-size: 0.875rem !important;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 0.25rem;
}

#quick-action-btn .MuiList-root {
    padding: 0.5rem;
    border: 1px solid var(--bs-gray-300);
    border-radius: 4px;
}

#quick-action-menu .MuiMenu-list {
    padding: 0.5rem;
}

#quick-action-menu .MuiMenuItem-root {
    padding: 0.625rem 0.5rem;
}

.cm-breadcrumb-back {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.rdrDateRangePickerWrapper .rdrInputRanges {
    display: none;
}

.cm-date-range-val {
    color: var(--bs-blue-800);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
}

.cm-list-range{
    padding: 0.4rem 0.5rem;
    border-radius: 4px;
}

.cm-date-range-val input {
    font-family: 'Noto Sans', sans-serif;
    border: none;
    background-color: transparent;
    color: var(--bs-blue-800);
    display: block;
    width: 100%;
    min-width: 160px;
}

.cm-dt-field-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cm-dt-actions {
    cursor: default !important;
}

.cm-dt-actions .cm-apply-range-btn {
    padding: 6px 12px;
}

/* Responsive */
@media screen and (max-width: 767px) {
    
    .cm-breadcrumb-container {
        position: static;
        padding: 1rem !important;
    }
    
    .cm-breadcrumb-container > div {
        padding: 0 !important;
    }

}