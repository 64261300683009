.partialMarkingBox {
    width: 112px;
    /* height: 25px; */
    border : 1px solid gray;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-left: 0.4rem;
    /* overflow: hidden; */
}

.partialMarkingBoxInput {
    width: 40px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius : 4px;

}

.partialMarkingBoxInput input {
    width: 30px;
    height: 20px;
    color: gray;
    /* border : 1px solid red; */
    font-size: small;
    border: none;
    outline: none;
}

.partialMarkingBox span {
    width: 70px;
    height: 35px;
    color: gray;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid gray;
    /* border-right: 1px solid gray;
    border-bottom: 1px solid gray; */
    /* border-top-right-radius: 4px;
    border-bottom-right-radius : 4px; */
}


.disabledClass {
    color: gray;
    background-color: #F8FAFC;
    cursor: not-allowed;
}

.enabled_automatic_evaluation_box{
    display: flex;
    gap: 30px;
}

.long_answer_question_type_select-box {
    width: max-content;
}

.minimumWordCountBox {
    width: 110px;
    height: 35px;
    display: flex;
    border-radius: 4px;
}

.minimumWordCountInputBox{
    width: 60px;
    /* height: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius : 4px;
}

.minimumWordCountInputBox input {
    width: 50px;
    /* height: 20px; */
    color: gray;
    font-size: small;
    border: none;
    outline: none;
    padding-left: 7px;
}

.questionTextEditor .cke_contents { 
    min-height: 120px !important; 
}