.cm-create-task-form-container.cm-add-tag-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}

.language-select{
    width: 90%;
}

.alert{
    margin-top: 20px;
    margin-bottom: 0;
    color: #8B6420;
    background: #fcf3e2;
    border: 1px solid #f3ce85;
    padding: 10px;
    border-radius: 4px;
}

.transformed-checkBox {
    transform: scale(1.1);
}