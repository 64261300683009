body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {

  font-family: "MMC Display";

  src: local("MMC Display"),

    url("./assets/fonts/MMCDisplay_Bd.ttf") format("truetype");

  font-weight: bold;

}

@font-face {

  font-family: "MMC Display";

  src: local("MMC Display"),

    url("./assets/fonts/MMCDisplay_Rg.ttf") format("truetype");

  font-weight: normal;

}

/* Global Styles Start */

*,
:after,
:before {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0;
}

table td {
  border-width: 1px;
  padding: 5px;
}

.cm-mmc-font {
  font-family: "MMC Display";
}

.fw-medium {
  font-weight: 500 !important;
}

body.txt-size-lg {
  font-size: 1.125rem;
}

.cm-sm-txt {
  font-size: 0.875rem !important;
}

.txt-size-lg .cm-sm-txt {
  font-size: 1rem;
}

body.txt-size-sm,
.txt-size-sm .cm-sm-txt,
.cm-xs-txt {
  font-size: 0.75rem;
}

.txt-size-lg .cm-xs-txt {
  font-size: 0.875rem;
}

.txt-size-sm .cm-xs-txt {
  font-size: 0.625rem;
}

a {
  text-decoration: none !important;
}

.cm-btn-radius {
  border-radius: 4px !important;
}

.cm-pointer {
  cursor: pointer;
}

.cm-pointer-none {
  pointer-events: none;
}

.cm-action-disabled-bg {
  background-color: rgba(11, 65, 173, 0.40) !important;
}

.cm-gradient-txt {
  background: linear-gradient(263.66deg, #00968F 0%, #009DE0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -ms-background-clip: text;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  -webkit-text-fill-color: transparent;
}

.cm-lr-pad {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.cm-anim-comp svg {
  display: block;
}

.cm-admin-page-container {
  padding-top: 128px;
}

.cm-btn {
  padding: 0.625rem 1rem;
  border-radius: 4px;
}

.cm-prim-btn:hover,
.cm-prim-btn:focus,
.cm-prim-btn:active {
  background-color: var(--bs-blue-500) !important;
  border-color: var(--bs-blue-500) !important;
}

.cm-trans-btn {
  background-color: transparent;
  border-color: var(--bs-blue-gray-300);
}

.cm-trans-btn:hover,
.cm-trans-btn:focus,
.cm-trans-btn:active {
  background-color: var(--bs-action-blue);
  border-color: var(--bs-action-blue);
  color: var(--bs-white);
}

.cm-white-trans-btn {
  padding: 0.625rem 1rem;
  border: 1px solid var(--bs-blue-gray-300);
  border-radius: 4px;
}

.cm-light-hover:hover {
  background-color: var(--bs-blue-gray-50) !important;
}

.cm-light-hover:hover svg {
  color: var(--bs-blue-gray-500) !important;
}

.cm-action-hover:hover {
  background-color: var(--bs-action-blue) !important;
  border-color: var(--bs-action-blue) !important;
  color: var(--bs-white) !important;
}

.cm-action-hover:hover svg {
  color: var(--bs-white) !important;
}

.cm-lt-action-hover:hover {
  background-color: var(--bs-action-blue-2) !important;
  border-color: var(--bs-action-blue-2) !important;
  color: var(--bs-white) !important;
}

.cm-danger-hover:hover {
  background-color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}

#root .MuiOutlinedInput-notchedOutline {
  border-color: var(--bs-blue-gray-300) !important;
}

#root .cm-disable-btn {
  background-color: rgba(11, 65, 173, 0.4) !important;
  pointer-events: none !important;
  border-color: transparent !important;
  cursor: default !important;
}

.cm-fp-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}

.cm-btn-loading {
  padding: 0 !important;
}

.cm-loader-btn .cm-anim-comp svg {
  max-width: 90px;
}

/* Custom Global Dynamic Component */
.cm-reverse-dir {
  display: flex;
  flex-direction: row-reverse;
}

.cm-mar-left-icon .cm-mar-icon {
  margin-left: 8px;
}

.cm-mar-right-icon .cm-mar-icon {
  margin-right: 8px;
}

/* 
.cm-content-wrappper {
  margin-top: 64px !important;
  margin-top: 0;
} */

/* Global Styles End */

#quesText .ck.ck-editor__editable {
  height: 15vh !important;
}

.ck.ck-editor__editable {
  font-size: 14px !important;
  font-family: "Noto Sans", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: 24px;
  color: #333333;
}

.navbar {
  background-color: #002C77;
}

.vertical {
  border-left: 1px solid #595858;
  height: 28px;
  position: absolute;
  top: 25%;
  left: 11.5vw;
  width: 0
}



.nav>li>a {
  float: none;
  padding: 8px 16px 8px;
  /* margin: 7px 0px 6px; */
  border-radius: 4px;
  font-size: 14px;
  text-transform: capitalize;
  /* font-weight: bold; */
  text-decoration: none !important;
}

.vertical-header-line {
  align-self: stretch;
  width: 1px;
  background-color: white;
  opacity: 0.25;
  height: 30px;
  float: right;
  margin: 5px 10px;
  margin-left: -5vw;
  position: relative;
}

.navbar-brand {
  margin-left: 20px;
}

.col-lg-5 {
  display: flex;
  position: relative;
  top: 0.5vw;
}

.nav {
  display: contents;
}

#accountMenu {
  color: white !important;
}

.dropdown-menu {
  left: -220% !important;
}

a {
  text-decoration: none;
  color: black;
}

.dropdown-toggle::after {
  display: none !important;
}

.im {
  width: calc(100% - 1vw);
}

.cm-truncate-1 {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#questionCheckText {
  font-size: 1.3vw !important;
  margin-top: 0.4vw;
}

#questionCheckNum {
  font-size: 1.8vw !important;
  font-weight: 500;
}

.cm-select-modal .Mui-selected {
  background: rgba(11, 65, 173, 0.05) !important;
  border-radius: 4px;
  color: var(--bs-action-blue) !important;
}

.cm-select-modal .MuiPaper-root {
  max-height: 356px;
}

.cm-select-modal .MuiMenuItem-root:not(.Mui-selected):hover {
  background: var(--bs-gray-50) !important;
}

.cm-select-modal .MuiMenuItem-root {
  font-size: 0.875rem;
  padding: 0.625rem 0.5rem !important;
  overflow: hidden;
}

.cm-select-modal .MuiMenuItem-root.cm-blocked {
  opacity: 1;
}

.cm-select-modal .MuiMenuItem-root.cm-blocked .cm-locked-icon {
  right: 8px;
}

.cm-select-modal .MuiList-root {
  padding: 0.5rem;
}

.cm-inp-field>input:focus {
  outline: none !important;
}

.cm-inp-field input::-webkit-input-placeholder {
  /* Edge */
  color: var(--bs-blue-gray-500);
  font-weight: 500;
}

.cm-inp-field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--bs-blue-gray-500);
  font-weight: 500;
}

.cm-inp-field input::placeholder {
  color: var(--bs-blue-gray-500);
  font-weight: 500;
}

.cm-inp-field.cm-error-input input::-webkit-input-placeholder {
  /* Edge */
  color: var(--bs-danger);
}

.cm-inp-field.cm-error-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--bs-danger);
}

.cm-inp-field.cm-error-input input::placeholder {
  color: var(--bs-danger);
}

.cm-error-input>svg {
  top: 12px;
  right: 16px;
}

.cm-error-input>input {
  padding-right: 48px !important;
}

.cm-error-input>input {
  color: var(--bs-danger) !important;
}

@media only screen and (min-width: 1550px) {
  .col-5 {
    width: 41 !important;
    margin-left: 10% !important;
    padding-right: 2rem !important;
  }
}


#new {
  background-color: white;
  border: 1px solid lightgrey;
  position: relative;
}

.px-md-5 {
  padding-left: 10vw !important;
  margin-left: 5vw !important;
}

.p-4 {
  padding: 2rem !important;
}

.wrap {
  display: flex;
  width: 100%;
  height: calc(100vh);
  margin-top: -30px;
}


.panel {
  background-color: white;
  top: 60px;
  position: relative;
  width: 90%;
  /* left: calc(100% - 1100px); */
  left: calc(100% - 1450px);
  /* margin-bottom: 50px; */
  height: calc(100vh - 500px);
  margin-bottom: 50px;
}

.sphere {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size: 500%;
  position: relative;
  box-shadow: inset -10px -10px 100px #000, 10px 10px 20px black, inset 0px 0px 10px black;
  display: inline-block;
  background-color: lightgreen;
  margin: 5%;
}

.py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

#box {
  border-radius: 0.25rem !important;
  background-color: white !important;
}

.p-3 {
  padding: 2rem !important;
}

.text-dark {
  font-size: 1.4vw !important;
}


.mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none !important;
}

.selectpicker {
  border: none;
  background-color: #e3e3e3;
  color: #313131;
}

.nav-tabs {
  background-color: white !important;
}

.nav-tabs .nav-link {
  margin-left: 1vw;
  color: #495057;

}

.card {
  border: none !important;
}

.nav-tabs .nav-link.active {
  background-color: white !important;
  border: none !important;
  border-bottom: 1.5px solid #2d98da !important;
}

.cm-btn {
  font-size: 16px;
  text-decoration: none;
  text-align: start;
}

.cm-disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
}

.card-header {
  background-color: white;
  border: none !important;
  color: #4E6287 !important;
  padding: 20px 20px;
}

#box {
  border: none !important;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #E5EDF4;
  border-color: #337ab7;
}

.active,
#pageIndex:hover {
  z-index: 3;
  border-color: #337ab7 !important;
  color: white !important;
}

button:disabled {
  color: #4E6287 !important;
  pointer-events: none;
  opacity: 0.4;
}

#link {
  padding: 0;
}

.fa-check-circle-0 {
  font-size: 40px;
  margin-right: 1vw;
  color: rgb(39, 174, 96);
  -webkit-text-stroke: 3px white;
}

#page {
  color: #4E6287;
}

#fa-ellipsis-v {
  font-size: 1.4em;
  -webkit-text-stroke: 2px white;
}

.details {
  text-decoration: none;
  margin-right: 5px;
  color: #4E6287;
}

.fa-check-circle-o {
  font-size: 40px;
  margin-right: 1vw;
  color: rgb(39, 174, 96);
  margin-top: -0.4vw;
  -webkit-text-stroke: 3px white;
}

.totalCount {
  font-size: 36px;
}

.fa-exclamation {
  font-size: 40px;
  color: rgb(194, 57, 52);
  -webkit-text-stroke: 3px white;
  margin-top: -0.2vw;
}

.plagCount {
  margin-top: 0.3vw;
  font-size: 36px;
}


.plagCheckLabel {
  font-size: 24px;
  font-weight: 400;
}

.tbody {
  background-color: #fefee71f !important;
  font-size: 16px !important;
}

.table-link {
  display: grid;
  white-space: break-spaces;
}

#tdRow {
  padding: 1rem 1rem !important;
}

.card-footer {
  padding: 20px 20px;
  border: none !important;
}

.ellipsis {
  color: grey;
  position: relative;
  left: 180px;
  -webkit-text-stroke: 2px white;
}

.fa-stop-circle {
  font-size: 1.8em;
  color: #526381;
  background-color: white;
  -webkit-text-stroke: 2px white;
}

.fa-ellipsis {
  font-size: 1.4em;
}

#completed {
  color: #14853D;
  background-color: rgba(20, 133, 61, 0.1);
  border-radius: 16px;
}

#start {
  color: #DB4D8A;
  background-color: rgba(219, 77, 138, 0.1);
  border-radius: 16px;
}

#progress {
  display: inline-grid;
  color: #526381;
  background-color: rgba(140, 159, 193, 0.1);
  border-radius: 16px;
}

.fa-repeat {
  color: #14853D;
  font-size: 1.4em;
  -webkit-text-stroke: 1px white;
}

.fa-download {
  font-size: 1.4em;
  -webkit-text-stroke: 1px white;
  color: rgba(140, 159, 193, 0.1);
}

#stop {
  margin-right: 10px;
  color: #4E6287;
}

#times {
  margin-right: 10px;
  border-color: #ccc;
  color: #DB4D8A;
  font-size: 24px;
  -webkit-text-stroke: 2px white;
}

#download {
  margin-right: 10px;
  color: #4E6287;
}

#reRun {
  margin-right: 10px;
  color: #4E6287;
}

#loader {
  align-items: center;
  margin-top: 250px;
}

#submitButton {
  left: 45%;
  background-color: #2d98da !important;
  color: white !important;
}

.status-padding {
  padding-top: 0.25rem !important;
}

.no-checks {
  color: #4E6287 !important;
  font-size: 22px;
}

.msgColor {
  color: #001F52;
}

#reRun {
  border: none;
  padding-left: 3vw !important;
}

.cm-event-library-content-wrapper {
  width: calc(100% - 350px);
  min-height: calc(100vh - 128px);
  margin-left: 350px;
}

.cm-event-sidebar-widget {
  padding-left: 2rem;
  padding-right: 2rem;
}

.cm-add-task-modal-container .cm-add-task-trigger {
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--bs-blue-gray-300);
  border-radius: 4px;
}

.cm-taskdetail-modal-header {
  padding: 1.25rem 2rem;
}

.cm-taskdetail-modal-sh {
  padding: 0.75rem 2rem;
  border-top: 1px solid var(--bs-blue-gray-100);
  border-bottom: 1px solid var(--bs-blue-gray-100);
}

.cm-taskdetail-modal-content {
  padding: 1.5rem 2rem;
}

.cm-add-task-content-header .cm-task-search input {
  width: 300px;
  border-radius: 4px;
  padding: 0.625rem 1rem;
  border: 1px solid var(--bs-gray-300);
  font-family: 'Noto Sans', sans-serif;
  outline: none !important;
  line-height: 20px;
}

.cm-add-task-content-header .cm-task-search svg {
  top: calc(50% - 7px);
  right: 12px;
}

.cm-add-task-content-header .cm-task-search input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-refresh-modal-task-btn {
  padding: 0.75rem 1rem;
  border: 1px solid var(--bs-gray-300);
  border-radius: 4px;
}

.cm-taskdetail-modal-footer {
  border-top: 1px solid var(--bs-blue-gray-100);
  padding: 1rem 2rem;
}

.cm-taskdetail-modal-footer button+button {
  margin-left: 0.75rem;
}

.cm-taskdetail-modal-footer .cm-trans-btn:hover {
  color: var(--bs-white) !important;
}

.cm-event-library-top-content>p {
  margin-top: 0.625rem;
}

.cm-event-progress-steps ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.cm-event-progress-steps ul input[type="radio"] {
  margin-right: 0.75rem;
}

.cm-event-progress-steps ul li+li {
  margin-top: 0.25rem;
}

.cm-event-progress-controls .cm-sidebar-back-btn {
  padding: 0.5rem 2.25rem;
  border: 1px solid var(--bs-blue-gray-300);
  border-radius: 4px;
}

.cm-event-progress-controls .cm-sidebar-save-btn {
  padding: 0.45rem 0.75rem;
  border-radius: 4px;
}

.cm-event-library-hr {
  height: 1px;
  width: 200px;
  margin: 2rem 3rem;
}

.cm-taskdetail-modal-footer-bottom .cm-add-task-disabled {
  background: rgba(11, 65, 173, 0.4) !important;
  pointer-events: none;
}

.cm-event-details-form {
  max-width: 90%;
}

.cm-event-details-form input {
  padding: 0.625rem 1rem;
}

.cm-event-details-form .cm-date-field-icon,
.cm-event-details-form .cm-select-arrow {
  right: 12px;
  top: calc(50% - 8px);
}

.cm-date-field .MuiSvgIcon-root,
.cm-select-field .MuiSvgIcon-root,
.cm-date-field .MuiButtonBase-root .MuiTouchRipple-root {
  display: none;
}

.cm-date-field .MuiButtonBase-root {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent !important;
}

.cm-event-progress-steps li:after {
  content: '';
  width: 2px;
  height: 0;
  position: absolute;
  left: 21px;
  background-color: var(--bs-blue-500);
  top: -16px;
  transition: all 300ms ease;
}

.cm-event-progress-steps .active-step+.active-step:after {
  height: 28px;
}

.cm-event-banner-container {
  background: linear-gradient(263.66deg, #EE3D8B 0%, #009DE0 100%);
  border-radius: 0px 0px 8px 8px;
  padding: 3rem 3rem 2rem;
}

.cm-event-banner-container .cm-event-status {
  background: rgba(11, 65, 173, 0.2);
  border-radius: 16px;
}

.cm-event-banner-container .cm-event-time {
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.cm-event-banner-container .cm-event-time span {
  padding-left: 0.625rem;
}

.cm-event-banner-container .cm-right-col {
  right: 0;
  bottom: 0;
}

.cm-event-banner-container .cm-left-col {
  width: 450px;
}

.cm-event-banner-container .cm-event-link-wrapper {
  border: 1px solid var(--bs-blue-gray-300);
  background: rgba(11, 65, 173, 0.4);
  padding: 0.75rem 1rem;
  border-radius: 4px;
}

.cm-event-banner-container .cm-event-link-wrapper span {
  padding-left: 0.625rem;
}

.cm-event-link-wrapper.cm-event-link-active span {
  padding-left: 0;
}

.cm-event-setting-options-fields span {
  padding-left: 1.75rem;
}

.cm-event-setting-options-fields label+label {
  margin-top: 1.25rem;
}

.cm-event-setting-options-fields input[type="radio"] {
  width: 20px;
  height: 20px;
}

.cm-event-setting-options {
  border-radius: 8px;
}

.cm-basic-details-info input {
  padding: 0.625rem 1rem;
  border: 1px solid var(--bs-blue-gray-300);
  background-color: var(--bs-blue-gray-50);
  border-radius: 4px;
}

.cm-basic-details-info .cm-icon-field svg {
  top: calc(50% - 8px);
  left: 16px;
}

.cm-basic-details-info .cm-icon-field input {
  padding-left: 42px;
}

.cm-event-notes-field {
  padding: 1.5rem;
}

.cm-event-notes-field textarea {
  width: 100%;
  border: 1px solid var(--bs-blue-gray-300);
  border-radius: 4px;
  height: 172px;
}

.cm-event-library-sidebar-wrapper .cm-small-back-btn {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 85px;
  text-align: center;
}

.cm-other-settings-item {
  border-radius: 4px;
  padding: 1rem 2rem;
}

.cm-disable-txt-editor {
  pointer-events: none;
}

.cm-event-notes-field p {
  font-size: 14px;
}

.navbar {
  background-color: #002C77;
}



.active {

  color: #FFFFFF;
  text-decoration: none !important;
  background-color: #001F52 !important;
  font-weight: 500;
}

.nav>li>a {
  float: none;
  padding: 8px 16px 8px;
  /* margin: 7px 0px 6px; */
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  text-transform: capitalize;
  /* font-weight: bold; */
  text-decoration: none !important;
}

.vertical-header-line {
  align-self: stretch;
  width: 1px;
  background-color: white;
  opacity: 0.25;
  height: 30px;
  float: right;
  margin: -5px 10px;
  margin-left: -5vw;
  position: relative;
}

.navbar-brand {
  margin-left: 20px;
}

.col-lg-5 {
  display: flex;
  position: relative;
  top: 0.5vw;
}

.nav {
  display: contents;
}

#accountMenu {
  color: white !important;
}

a {
  text-decoration: none;
  color: black;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn.show {
  border: none !important;
}

.runPlag {
  white-space: nowrap !important;
}

.tabs {
  position: relative;
  top: 10px;
  flex-wrap: wrap;
}

.linkActive {
  background-color: white !important;
  color: #6e8497 !important;
}

.active {
  color: #FFFFFF;
  text-decoration: none !important;
  background-color: #001F52 !important;
  font-weight: 500;
}

.body {
  background-color: #F8FAFC !important;
  height: 120vh !important;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.userPosition {
  left: -1%;
  position: relative;
}

.ck.ck-editor__editable {
  min-height: 200px !important;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem !important;
}

.cm-small-editor .ck.ck-editor__editable {
  min-height: 40px !important;
}


.cm-create-task-form-container.cm-lang-modal-container .ck-content,
.cm-create-task-form-container.cm-lang-modal-container .ck-source-editing-area {
  height: 340px !important;
}

.cm-create-task-form-container.cm-lang-modal-container .ck-content {
  border: 1px solid var(--bs-blue-gray-300) !important;
  box-shadow: none !important;
}

.cm-create-task-form-container.cm-lang-modal-container .ck.ck-toolbar {
  border: none;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 9999;
}

#testcase .ck.ck-editor__editable {
  height: 7vh !important;
}

.cm-lang-modal-container .ck-content span {
  color: var(--bs-danger);
  font-style: normal;
  font-weight: 500;
  text-decoration: underline;
}

.cm-select-modal .Mui-selected {
  background: rgba(11, 65, 173, 0.05) !important;
  border-radius: 4px;
  color: var(--bs-action-blue) !important;
}

.cm-select-modal .MuiPaper-root {
  max-height: 356px;
}

.cm-select-modal .MuiMenuItem-root:not(.Mui-selected):hover {
  background: var(--bs-gray-50) !important;
}

.cm-select-modal .MuiMenuItem-root {
  font-size: 0.875rem;
  padding: 0.625rem 0.5rem !important;
  overflow: hidden;
}

.cm-select-modal .MuiMenuItem-root.cm-blocked {
  opacity: 1;
}

.cm-select-modal .MuiMenuItem-root.cm-blocked .cm-locked-icon {
  right: 8px;
}

.cm-select-modal .MuiList-root {
  padding: 0.5rem;
}

.cm-select-arrow {
  right: 12px;
  top: calc(50% - 8px);
}


@media only screen and (max-width: 1490px) {
  .cm-quest-lib-actions li a {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .cm-quest-lib-actions li span {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .cm-quest-lib-container .cm-candidate-report-list-content .cm-candidate-report-item .col-sm-2 {
    padding: 1rem 0.5rem;
  }

  /* .cm-quest-lib-container  .cm-candidate-report-list-header .col-sm-1 p {
    padding-left:1.5rem !important;
  }; */
}


@media only screen and (max-width: 1120px) {
  .cm-quest-lib-actions li a {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }

  .cm-quest-lib-actions li span {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }

  .cm-quest-lib-container .cm-candidate-report-list-content .cm-candidate-report-item .col-sm-2 {
    padding: 1rem 0.2rem;
  }
}

@media only screen and (max-width: 960px) {
  .cm-quest-lib-actions li a {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }

  .cm-quest-lib-actions li span {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }

  .cm-quest-lib-container .cm-candidate-report-list-content .cm-candidate-report-item .col-sm-2 {
    padding: 1rem 0.1rem;
  }

  .cm-quest-lib-container .cm-candidate-report-list-content .cm-candidate-report-item .col-sm-2 .cm-sm-txt {
    font-size: 0.675rem !important;
  }
}


.separate {
  border-bottom: 1px solid var(--bs-blue-gray-100);
}

.dashboardCards {
  font-size: 32px;
  color: #009DE0;
  font-weight: 500;
}

.txt-align {
  text-align: center !important;
}

.cm-helper-icon input {
  padding-right: 36px !important;
}

.cm-comment-data {
  white-space: pre-wrap;
}

.ck.ck-powered-by {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ck-widget.html-object-embed:before {
  display: none;
}

span.html-object-embed.ck-widget {
  display: contents;
}

._snippet {
  width: 100%;
}

.ck-widget.html-object-embed .html-object-embed__content {
  pointer-events: auto;
}

.cm-input-text-area {
  min-height: 150px !important;
  max-height: 200px !important;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  border: 1px solid var(--ck-color-input-border);
}

.cm-btn-loader {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
}

.cm-custom-btn-label {
  position: absolute;
}

.cm-custom-btn {
  max-width: 200px;
  position: relative;


}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

img {
  max-width: 100%;
}

/* CKEditor 4 Start */
#main-toolbar .cke_toolbox:after {
  content: '';
  display: table;
  clear: both;
}

#main-toolbar .cke_top {
  border-top: none;
  border-right: none;
  border-bottom-color: var(--bs-blue-gray-100);
  border-radius: 0;
  border-left: 0;
}

#main-toolbar .cke_toolbar_last .cke_toolbar_end {
  display: none;
}

#main-toolbar {
  position: sticky;
  top: 0;
  z-index: 9;
}
/* CKEditor 4 End */


.cm-toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
 
.cm-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
 
.cm-toggle-switch .cm-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 17px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
 
.cm-toggle-switch.cm-toggle-switch-disable .cm-slider {
  cursor: default;
}
 
.cm-toggle-switch .cm-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
 
.cm-toggle-switch input:checked + .cm-slider {
  background-color: #2C6EF2;
}
 
.cm-toggle-switch.cm-toggle-switch-disable input:checked + .cm-slider {
  background-color: rgba(11, 65, 173, 0.40);
}
 
.cm-toggle-switch input:focus + .cm-slider {
  box-shadow: 0 0 1px #2C6EF2;
}
 
.cm-toggle-switch.cm-toggle-switch-disable input:focus + .cm-slider {
  box-shadow: 0 0 1px rgba(11, 65, 173, 0.40);
}
 
.cm-toggle-switch input:checked + .cm-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}