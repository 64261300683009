
.cm-auth-page-container {
    /* margin-top: 64px; */
}

.cm-auth-content-wrap {
    padding: 9.75rem 4rem 2rem;
}

.cm-signup-content-wrap {
    padding-top: 5.625rem;
}

.cm-resetpass-content-wrap {
    padding-top: 9.25rem;
}

.cm-auth-content-wrap h4 {
    padding-top: 27px;
}

.cm-auth-bg-container {
    background-size: cover;
    background-position: top;
    min-height: calc(100vh - 59px);
}

.cm-auth-hr {
    width: 159px;
    height: 1px;
    margin: 3rem 0;
    opacity: 1;
}

.cm-icon-field .cm-pass-visiblility-changer,
.cm-icon-field .cm-mob-icon {
    right: 15px;
    cursor: pointer;
    top: 8px;
    font-size: 1rem;
}

.cm-pass-info-icon {
    right: 15px;
    cursor: pointer;
    top: 8px;
    font-size: 1rem;
}

.cm-double-icon-field.cm-icon-field .cm-pass-visiblility-changer {
    right: 48px;
}

.cm-double-icon-field.cm-icon-field > input {
    padding-right: 76px;
}

.cm-auth-form input {
    padding-top: 0.53rem; 
    padding-bottom: 0.53rem;
    font-size: 0.875rem;
}

.txt-size-lg .cm-auth-form input {
    font-size: calc(0.875rem + 2px);
}

.txt-size-sm .cm-auth-form input {
    font-size: calc(0.875rem - 2px);
}

.cm-auth-form a {
    text-decoration: none;
}

.cm-auth-content-wrap .cm-auth-footer {
    padding-top: 6.15rem;
}

.cm-auth-footer p {
    font-size: 0.75rem;
}

.cm-info-badge-wrap {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
}

.cm-auth-content-wrap .cm-prim-btn:hover,
.cm-auth-content-wrap .cm-prim-btn:focus,
.cm-auth-content-wrap .cm-prim-btn:active {
    background-color: var(--bs-blue-500) !important;
    border-color: var(--bs-blue-500) !important;
}

.cm-auth-content-wrap .cm-trans-btn:hover,
.cm-auth-content-wrap .cm-trans-btn:focus,
.cm-auth-content-wrap .cm-trans-btn:active {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-blue-gray-300);
}

.cm-verify-mail-form .cm-verify-mail-txt {
    font-size: 1.125rem;
}

.text-size-lg .cm-verify-mail-form .cm-verify-mail-txt {
    font-size: 1.25rem;
}

.text-size-sm .cm-verify-mail-form .cm-verify-mail-txt {
    font-size: 1rem;
}

.cm-personal-details-wrap .cm-profile-form > .MuiPaper-root {
    margin: 0;
    box-shadow: none;
}

.cm-personal-details-wrap .cm-profile-form > .MuiPaper-root::before {
    content: none;
}

.cm-personal-details-wrap .cm-profile-form .MuiAccordionDetails-root {
    padding: 0;
}

.cm-back-login-btn {
    padding: 0.625rem 1rem;
    border-radius: 4px;
    display: inline-block;
    margin-top: 2rem;
}

.cm-auth-footer > p {
    position: relative;
    top: 3px;
}

/* Temp Styles Start */

/* .cm-auth-page-container {
    overflow: hidden;
    height: calc(100% - 64px);
    margin-top: 64px;
}

.cm-auth-page-container .cm-auth-content-wrap {
    overflow: auto;
    margin-top: 32px;
    padding-top: 0;
}

.cm-auth-page-container .cm-auth-content-wrap .cm-auth-content-inner {
    height: calc(100vh - 128px);
}

.cm-auth-bg-container {
    min-height: calc(100vh - 64px);
} */

/* Temp Styles End */



/* Responsive */
@media screen and (min-width: 1200px) {
    
    .cm-auth-content-wrap h2 > span {
        font-size: 3rem;
    }

}

@media screen and (max-width: 991px) {
    
    .cm-auth-content-wrap {
        padding: 9.75rem 2rem 2rem;
    }

}

@media screen and (max-width: 767px) {
    
    .cm-auth-content-wrap {
        background-image: url("../../assets/images/auth/BG-mobile.jpg");
        padding: 2.5rem 1rem 2rem;
        min-height: calc(100vh - 51px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .cm-auth-page-container {
        margin-top: 0;
    }

    .cm-auth-content-wrap h2 {
        color: var(--bs-blue-gray-300) !important;
        line-height: 60px;
    }

    .cm-auth-content-wrap h2 span,
    .cm-auth-form label,
    .cm-auth-form a {
        color: var(--bs-white) !important;
    }

    .cm-auth-content-wrap h4 {
        color: var(--bs-blue-gray-300) !important;
    }

    
    .cm-auth-hr {
        margin: 2rem 0;
        background-color: var(--bs-white) !important;
        height: 2px;
    }

    .cm-auth-form .cm-trans-btn {
        color: var(--bs-blue-800) !important;
    }

    .cm-form-btn-group > .cm-btn {
        min-width: 145px;
        text-align: center;
        justify-content: center;
    }

    .cm-auth-content-wrap h4 {
        font-size: 1rem;
    }

    .cm-auth-content-inner .cm-info-badge-wrap {
        margin-top: 1.5rem;
    }

}

@media screen and (max-width: 420px) {
    
    .cm-form-btn-group > .cm-btn {
        min-width: auto;
    }

}