.cm-event-library-sidebar-wrapper {
    width: 350px;
    padding: 3rem 0;
    background-color: var(--bs-white);
    position: fixed;
    top: 64px;
    left: 0;
    height: 100vh;

}

.cm-event-library-content-wrapper {
    width: calc(100% - 350px);
    min-height: calc(100vh - 128px);
}

.cm-event-sidebar-widget {
    padding-left: 2rem;
    padding-right: 2rem;
}

.cm-add-task-modal-container .cm-add-task-trigger {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.cm-taskdetail-modal-header {
    padding: 1.25rem 2rem;
}

.cm-taskdetail-modal-sh {
    padding: 0.75rem 2rem;
    border-top: 1px solid var(--bs-blue-gray-100);
    border-bottom: 1px solid var(--bs-blue-gray-100);
}

.cm-taskdetail-modal-content {
    padding: 1.5rem 2rem;
}

.cm-add-task-content-header .cm-task-search input {
    width: 300px;
    border-radius: 4px;
    padding: 0.625rem 1rem;
    border: 1px solid var(--bs-gray-300);
    font-family: 'Noto Sans', sans-serif;
    outline: none !important;
    line-height: 20px;
}

.cm-add-task-content-header .cm-task-search svg {
    top: calc(50% - 7px);
    right: 12px;
}

.cm-add-task-content-header .cm-task-search input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--bs-gray-400);
}
.cm-add-task-content-header .cm-task-search input::-moz-placeholder { /* Firefox 19+ */
    color: var(--bs-gray-400);
}
.cm-add-task-content-header .cm-task-search input:-ms-input-placeholder { /* IE 10+ */
    color: var(--bs-gray-400);
}
.cm-add-task-content-header .cm-task-search input:-moz-placeholder { /* Firefox 18- */
    color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-refresh-modal-task-btn {
    padding: 0.75rem 1rem;
    border: 1px solid var(--bs-gray-300);
    border-radius: 4px;
}

.cm-taskdetail-modal-footer {
    border-top: 1px solid var(--bs-blue-gray-100);
    padding: 1rem 2rem;
}

.cm-taskdetail-modal-footer button + button {
    margin-left: 0.75rem;
}

.cm-taskdetail-modal-footer .cm-trans-btn:hover {
    color: var(--bs-white) !important;
}

.cm-event-library-top-content > p {
    margin-top: 0.625rem;
}

.cm-event-progress-steps ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.cm-event-progress-steps ul input[type="radio"] {
    margin-right: 0.75rem;
}

.cm-event-progress-steps ul li + li {
    margin-top: 0.25rem;
}

.cm-event-progress-controls .cm-sidebar-back-btn {
    padding: 0.5rem 2.25rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.cm-event-progress-controls .cm-sidebar-save-btn {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
}

.cm-event-progress-controls .cm-sidebar-save-btn.cm-sidebar-save-btn-disabled {
    background: rgba(11, 65, 173, 0.4) !important;
    pointer-events: none;
}

.cm-event-library-hr {
    height: 1px;
    width: 200px;
    margin: 2rem 3rem;
}

.cm-taskdetail-modal-footer-bottom .cm-add-task-disabled {
    background: rgba(11, 65, 173, 0.4) !important;
    pointer-events: none;
}

.cm-event-details-form {
    max-width: 700px;
}

.cm-event-details-form input {
    padding: 0.625rem 1rem;
}

.cm-event-details-form .cm-date-field-icon,
.cm-event-details-form .cm-select-arrow {
    right: 12px;
    top: calc(50% - 8px);
}

.cm-date-field .MuiSvgIcon-root,
.cm-select-field .MuiSvgIcon-root,
.cm-date-field .MuiButtonBase-root .MuiTouchRipple-root {
    display: none;
}

.cm-date-field .MuiButtonBase-root {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent !important;
}

.cm-event-progress-steps li:after {
    content: '';
    width: 2px;
    height: 0;
    position: absolute;
    left: 21px;
    background-color: var(--bs-blue-500);
    top: -16px;
    transition: all 300ms ease;
}

.cm-event-progress-steps .active-step + .active-step:after {
    height: 28px;
}

.cm-event-banner-container {
    background: linear-gradient(263.66deg, #EE3D8B 0%, #009DE0 100%);
    border-radius: 0px 0px 8px 8px;
    padding: 3rem 3rem 2rem;
}

.cm-event-banner-container .cm-event-status {
    background: rgba(11, 65, 173, 0.2);
    border-radius: 16px;
}

.cm-event-banner-container .cm-event-time {
    margin-top: 0.625rem;
    margin-bottom: 1.5rem;
}

.cm-event-banner-container .cm-event-time span {
    padding-left: 0.625rem;
}

.cm-event-banner-container .cm-right-col {
    right: 0;
    bottom: 0;
}

.cm-event-banner-container .cm-left-col {
    width: 480px;
    width: calc(100% - 448px);
}

.cm-event-banner-container .cm-event-link-wrapper {
    border: 1px solid var(--bs-blue-gray-300);
    background: rgba(11, 65, 173, 0.4);
    padding: 0.75rem 1rem;
    border-radius: 4px;
    width: 450px;
}

.cm-event-banner-container .cm-event-link-wrapper span {
    padding-left: 0.625rem;
}

.cm-event-link-wrapper.cm-event-link-active span {
    padding-left: 0;
}

.cm-event-setting-options-fields span {
    padding-left: 1.75rem;
}

.cm-event-setting-options-fields label + label {
    margin-top: 1.25rem;
}

.cm-event-setting-options-fields input[type="radio"] {
    width: 20px;
    height: 20px;
}

.cm-event-setting-options {
    border-radius: 8px;
}

.cm-basic-details-info input {
    padding: 0.625rem 1rem;
    border: 1px solid var(--bs-blue-gray-300);
    background-color: var(--bs-blue-gray-50);
    border-radius: 4px;
}

.cm-basic-details-info .cm-icon-field svg {
    top: calc(50% - 8px);
    left: 16px;
}

.cm-basic-details-info .cm-icon-field input {
    padding-left: 42px;
}

.cm-event-notes-field {
    padding: 1.5rem;
}

.cm-event-notes-field textarea {
    width: 100%;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
    height: 172px;
}

.cm-event-library-sidebar-wrapper .cm-small-back-btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 85px;
    text-align: center;
}

.cm-other-settings-item {
    border-radius: 4px;
    padding: 1rem 2rem;
}

.cm-disable-txt-editor {
    pointer-events: none;
}

.cm-disable-txt-editor .ck-editor__top {
    display: none !important;
}

.cm-disable-txt-editor .ck-editor__main .ck-content {
    border: 1px solid var(--bs-blue-gray-300);
    color: var(--bs-blue-gray-700);
    padding: 0 1rem;
}

.cm-disable-txt-editor .ck-editor__main .ck-content * {
    color: var(--bs-blue-gray-700);
}

.cm-event-notes-field p {
    font-size: 14px;
}

.cm-add-task-modal-empty {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
}

.cm-reset-search-btn {
    border-radius: 4px;
    padding: 0.5rem 0.75rem;
}

.body
{
    background-color: #F5F5F5;
    height:100vh;
}

.bg-white
{
    background-color:white !important;
}

.headText
{
    font-family: 'Noto Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* Text/Blue 800 */

color: #001F52;
}

.labelText{
    font-family: 'Noto Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 12px;
color:#4E6287;
}

.cm-box-sm{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 16px;
gap: 10px;

width: 421.5px;
height: 40px;

/* Background/White */

background: #FFFFFF;
/* Borders/Blue Gray 300 */

border: 1px solid #BED3E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
}

.cm-box-lg
{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 16px;
gap: 10px;


/* Background/White */

background: #FFFFFF;
/* Borders/Blue Gray 300 */

border: 1px solid #BED3E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}

.border-sm
{
    border: 1px solid #BED3E4;
}

.breadcrumb-pos
{
    position:sticky;
    top:64px;
}

.ck-editor .ck-editor__main .ck-editor_editable
{
    height:200px !important;
}

.ml{
    margin-left: 500px;
}

.cm-lang-selector > div {
    z-index: 5;
}