.cm-event-library-sidebar-wrapper {
    width: 350px;
    padding: 3rem 0;
    background-color: var(--bs-white);
    position: fixed;
    top: 128px;
    left: 0;
    height: 100vh;
    position: sticky;
    top: 64px;
}

.cm-event-library-content-wrapper {
    width: calc(100% - 350px);
    min-height: auto;
    margin-left: 0;
}

.cm-event-sidebar-widget {
    padding-left: 2rem;
    padding-right: 2rem;
}

.cm-add-task-empty-container .cm-add-task-trigger,
.cm-sidebar-add-task-widget .cm-add-task-trigger,
.cm-add-task-modal-container .cm-add-task-trigger {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.cm-taskdetail-modal-header {
    padding: 1.25rem 2rem;
}

.cm-taskdetail-modal-sh {
    padding: 0.75rem 2rem;
    border-top: 1px solid var(--bs-blue-gray-100);
    border-bottom: 1px solid var(--bs-blue-gray-100);
}

.cm-taskdetail-modal-content {
    padding: 1.5rem 2rem;
}

.cm-add-task-content-header .cm-task-search input {
    width: 300px;
    border-radius: 4px;
    padding: 0.625rem 1rem;
    border: 1px solid var(--bs-gray-300);
    font-family: 'Noto Sans', sans-serif;
    outline: none !important;
    line-height: 20px;
}

.cm-add-task-content-header .cm-task-search svg {
    top: calc(50% - 7px);
    right: 12px;
}

.cm-add-task-content-header .cm-task-search input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-task-search input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--bs-gray-400);
}

.cm-add-task-content-header .cm-refresh-modal-task-btn {
    padding: 0.75rem 1rem;
    border: 1px solid var(--bs-gray-300);
    border-radius: 4px;
}

.cm-taskdetail-modal-footer {
    border-top: 1px solid var(--bs-blue-gray-100);
    padding: 1rem 2rem;
}

.cm-taskdetail-modal-footer button+button {
    margin-left: 0.75rem;
}

.cm-taskdetail-modal-footer .cm-trans-btn {
    background-color: var(--bs-white);
    padding: 6px 12px;
    border: 1px solid var(--bs-action-blue);
    border-radius: 4px;
}

.cm-taskdetail-modal-footer .cm-trans-btn:hover {
    background-color: var(--bs-action-blue) !important;
    color: var(--bs-white) !important;
    border-color: var(--bs-action-blue);
}

.cm-taskdetail-modal-footer .cm-trans-btn:focus {
    background-color: var(--bs-white);
    color: var(--bs-action-blue);
    border-color: var(--bs-blue-gray-300);
}

.cm-event-library-top-content>p {
    margin-top: 0.625rem;
}

.cm-event-progress-steps ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.cm-event-progress-steps ul input[type="radio"] {
    margin-right: 0.75rem;
}

.cm-event-progress-steps ul li+li {
    margin-top: 0.25rem;
}

.cm-event-progress-controls .cm-sidebar-back-btn {
    padding: 0.5rem 2.25rem;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
}

.cm-event-progress-controls .cm-sidebar-save-btn {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
}

.cm-event-progress-controls .cm-sidebar-save-btn.cm-sidebar-save-btn-disabled {
    background: rgba(11, 65, 173, 0.4) !important;
    pointer-events: none;
}

.cm-event-library-hr {
    height: 1px;
    width: 200px;
    margin: 2rem 3rem;
}

.cm-taskdetail-modal-footer-bottom .cm-add-task-disabled {
    background: rgba(11, 65, 173, 0.4) !important;
    pointer-events: none;
}

.cm-event-details-form {
    max-width: 90%;
}

.cm-event-details-form input {
    padding: 0.625rem 1rem;
}

.cm-event-details-form .cm-date-field-icon,
.cm-event-details-form .cm-select-arrow {
    right: 12px;
    top: calc(50% - 8px);
}

.cm-date-field .MuiSvgIcon-root,
.cm-select-field .MuiSvgIcon-root,
.cm-date-field .MuiButtonBase-root .MuiTouchRipple-root {
    display: none;
}

.cm-date-field .MuiButtonBase-root {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent !important;
}

.cm-event-progress-steps li:after {
    content: '';
    width: 2px;
    height: 0;
    position: absolute;
    left: 21px;
    background-color: var(--bs-blue-500);
    top: -16px;
    transition: all 300ms ease;
}

.cm-event-progress-steps .active-step+.active-step:after {
    height: 28px;
}

.cm-event-banner-container {
    background: linear-gradient(263.66deg, #EE3D8B 0%, #009DE0 100%);
    border-radius: 0px 0px 8px 8px;
    padding: 3rem 3rem 2rem;
}

/* .cm-event-banner-container h2 {
    flex: 1;
} */

.cm-event-banner-container .cm-event-status {
    background: rgba(11, 65, 173, 0.2);
    border-radius: 16px;
    white-space: nowrap;
}

.cm-event-banner-container .cm-event-time {
    margin-top: 0.625rem;
    margin-bottom: 1.5rem;
}

.cm-event-banner-container .cm-event-time span {
    padding-left: 0.625rem;
}

.cm-event-banner-container .cm-right-col {
    right: 0;
    bottom: 0;
}

.cm-event-banner-container .cm-left-col {
    width: 480px;
    width: calc(100% - 448px);
}

.cm-event-banner-container .cm-event-link-wrapper {
    border: 1px solid var(--bs-blue-gray-300);
    background: rgba(11, 65, 173, 0.4);
    padding: 0.75rem 1rem;
    border-radius: 4px;
    width: 450px;
}

.cm-event-banner-container .cm-event-link-wrapper span {
    padding-left: 0.625rem;
}

.cm-event-link-wrapper.cm-event-link-active span {
    padding-left: 0;
}

.cm-event-setting-options-fields span {
    padding-left: 1.75rem;
}

.cm-event-setting-options-fields label+label {
    margin-top: 1.25rem;
}

.cm-event-setting-options-fields input[type="radio"] {
    width: 20px;
    height: 20px;
}

.cm-event-setting-options {
    border-radius: 8px;
}

.cm-basic-details-info input {
    padding: 0.625rem 1rem;
    border: 1px solid var(--bs-blue-gray-300);
    background-color: var(--bs-blue-gray-50);
    border-radius: 4px;
}

.cm-basic-details-info .cm-icon-field svg {
    top: calc(50% - 8px);
    left: 16px;
}

.cm-basic-details-info .cm-icon-field input {
    padding-left: 42px;
}

.cm-event-notes-field {
    padding: 1.5rem;
}

.cm-event-notes-field textarea {
    width: 100%;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
    height: 172px;
}

.cm-event-library-sidebar-wrapper .cm-small-back-btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 85px;
    text-align: center;
}

.cm-other-settings-item {
    border-radius: 4px;
    padding: 1rem 2rem;
}

.cm-disable-txt-editor {
    pointer-events: none;
}

.cm-disable-txt-editor .ck-editor__top {
    display: none !important;
}

.cm-disable-txt-editor .ck-editor__main .ck-content {
    border: 1px solid var(--bs-blue-gray-300);
    color: var(--bs-blue-gray-700);
    padding: 0 1rem;
}

.cm-disable-txt-editor .ck-editor__main .ck-content * {
    color: var(--bs-blue-gray-700);
}

.cm-event-notes-field p {
    font-size: 14px;
}

.cm-add-task-modal-empty {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
}

.cm-event-details-form label {
    display: block;
}

.cm-reset-search-btn {
    border-radius: 4px;
    padding: 0.5rem 0.75rem;
}

#root .cm-select-field {
    font-size: 14px;
}

.cm-select-field .MuiSelect-select {
    padding: 0.625rem 1rem;
    color: var(--bs-blue-800);
    font-size: 14px;
    font-weight: 500;
}

.cm-io-spec-wrap p+p {
    padding-top: 0.5rem;
}

.cm-preview-quest-container .cm-content p {
    margin-bottom: 0.5rem;
}

.cm-editor-wrap {
    border-radius: 4px;
}

.cm-editor-wrap .cm-lang-sel fieldset {
    border: none;
    border-right: 1px solid;
    border-radius: 0;
}

.cm-editor-wrap #ace-editor {
    width: 100% !important;
    /* border: none; */
    border-top: none;
    /* height: auto !important; */
}

.cm-lang-sel .cm-sel-arrow-icon {
    right: 8px;
    top: calc(50% - 8px);
}

.cm-preview-quest-container .cm-review-quest-info {
    border-right: 1px solid var(--bs-blue-gray-300);
    padding-right: 1rem;
}

.cm-test-case-helper {
    right: 12px;
    top: 40px;
}

.table table {
    width: 100%;
    overflow: auto;
    display: block;
}

.table table th {
    background-color: #F8FAFC;
    line-height: 20px;
    vertical-align: middle;
    text-align: center;

}

.table table tbody {
    display: table;
    width: 100%;
}


.table table tr td {
    border: 0.5px solid rgb(71, 71, 71) !important;
    padding: 0.5rem 0.5rem !important;
    min-width: 150px;

}

/* Responsive */

@media screen and (max-width: 1366px) {

    .cm-event-banner-container .cm-right-col svg {
        max-width: 308px;
    }

    .cm-event-banner-container .cm-left-col {
        width: calc(100% - 308px);
    }

    .cm-event-banner-container .cm-event-link-wrapper {
        width: 100%;
        max-width: 450px;
    }

}

@media screen and (max-width: 1300px) {

    .cm-candidate-report-item .cm-candidate-pill {
        padding: 6px 15px;
    }

    .cm-event-banner-container .cm-right-col {
        display: none;
    }

    .cm-event-banner-container .cm-left-col {
        width: 100%;
    }

}

.cm-preview-quest-container .cm-acc-disabled {
    pointer-events: none;
}

.cm-code-editor-header .cm-left-col {
    flex: 1;
}

.resetBtnSize {
    font-size: 24px !important;
}

.pre-wrap-compilation-error {
    white-space: pre-wrap;
    word-spacing: 5px;
}

.question-description-text {
    white-space: pre-wrap;
    word-spacing: 5px;
    margin-left: 5px;
}

.resizable {
    overflow: auto;
    resize: both;
    height: 500px;
    width: auto;
}

.MuiSelect-outlined.Mui-disabled {
    background-color: #e9ecef !important;
    -webkit-text-fill-color: initial !important;
}

.difficulty_level_select-box {
    height: 20px;
    padding: 16px 2px;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 2px;
}