.cm-event-lib-task-err {
    background: rgba(197, 53, 50, 0.05);
    border-radius: 4px;
    margin: 0 0 0.5rem;
    background-color: white;
}

.cm-sidebar-btn-disabled {
    color: rgba(11, 65, 173, 0.4) !important;
}

.cm-allocate-disabled {
    background-color: rgba(11, 65, 173, 0.4) !important;
}

.cm-helper-icon input {
    padding-right: 36px;
}

.cm-sidebar-btn-disabled:active {
    pointer-events: none;
}

.cm-allocate-disabled:active {
    pointer-events: none;
}

.flag {
    font-size: 14px;
    color: #FF0000;
    margin: 0 5px;
}