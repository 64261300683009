.creation-option{
    /* border: solid; */
    width: 50%;
    padding: 0.2rem 1rem;
    margin: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    background-color: var(--bs-gray-300);
    opacity: 90%;
}

.hover-pointer{
    cursor: pointer;
}

