.cm-pagination-wrap span {
    padding: 4px 10px;
    border: 1px solid var(--bs-blue-gray-300);
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.cm-pagination-wrap > span.active {
    background-color: var(--bs-action-blue);
    color: var(--bs-white);
    border-color: var(--bs-action-blue);
    pointer-events: none;
}

.cm-pagination-wrap span + span {
    margin-left: 8px;
}

.cm-number-wrap > span {
    width: 45px;
    text-align: center;
}