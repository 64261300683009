#root .cm-multi-select-inp {
    padding: 4px;
    padding-right: 28px;
    min-height: 43px;
}

.cm-multi-select-inp .cm-select-chip {
    margin: 2px;
}

.cm-rotate-transition {
    transition: all 300ms;
}