.cm-taskdetail-modal-footer {
    border-top: 1px solid var(--bs-blue-gray-100);
    padding: 1rem 2rem;
}

.cm-taskdetail-modal-footer button {
    font-size: 14px;
}

.cm-taskdetail-modal-footer button + button {
    margin-left: 0.75rem;
}

.cm-taskdetail-modal-footer .cm-trans-btn:hover {
    color: var(--bs-white) !important;
}

.cm-tasklist-modal-wrapper .MuiBox-root {
    height: 100% !IMPORTANT;
}

.cm-create-task-form-container.cm-add-tag-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    overflow: auto;
    max-height: calc(100vh - 2rem);
}

.cm-create-task-form label {
    display: block;
}

.cm-create-task-form .cm-inp,
.cm-create-task-form .MuiInputBase-root {
    width: 100%;
    font-size: 14px;
    color: var(--bs-blue-800);
    line-height: 1.6;
}

.cm-create-task-form .cm-inp,
.cm-create-task-form .MuiInputBase-root .MuiSelect-select {
    padding: 0.625rem 1rem;
}

.cm-create-task-form .cm-inp {
    border: 1px solid var(--bs-blue-gray-300) !important;
    outline: none;
    border-radius: 4px;
    line-height: 1.5;
}

.cm-create-task-form .cm-inp,
.cm-create-task-form .MuiInputBase-root fieldset {
    border-color: var(--bs-blue-gray-300) !important;
}

.cm-form-btn-group button {
    font-size: 14px;
    padding: 0.625rem 1rem;
    border-radius: 4px;
}

.cm-create-task-form .cm-inp {
    font-family: "Noto Sans",sans-serif;
}

.cm-create-task-form-container .form-control {
   padding: 0.625rem 1rem;
}